import React from 'react'

import Blurb from '../components/Blurb'
import Layout from '../components/layout'
import SEO from '../components/SEO';


const ThingsToDo = () => (
  <Layout>
  <SEO title="Things To Do"/>
  <h2>Food</h2>

  <Blurb title="Deer Mountain Inn">
    The <a href="http://deermountaininn.com/dine/" target="_blank" rel="noopener noreferrer">restaurant at the Inn</a> is great. The chef, Ryan Tate, earned a Michelin star at his
    Manhattan restaurant, creatively named Le Restaurant. (He’ll be cooking for our wedding
    reception, too.) There’s a relatively simple Inn menu and also a big tasting menu that
    requires a reservation, and either way you won’t go wrong grabbing dinner here before
    the welcome party.
  </Blurb>

  <Blurb title="Tannersville">
    Downtown Tannersville is cute, and people like the two Mama’s Boy establishments,
    one a <a href="http://mamasboycatskills.com/pizza/mamas-boy-pizza.html" target="_blank" rel="noopener noreferrer">pizza joint</a> and
    the other a <a href="http://mamasboycatskills.com/mamas-boy-burgers.html" target="_blank" rel="noopener noreferrer">burger spot</a>.
    There’s also <a href="https://www.facebook.com/Twin-Peaks-Coffee-Donuts-167554073397379/" target="_blank" rel="noopener noreferrer">Twin
    Peaks Coffee & Donuts</a> and some other cafes and markets in Tannersville. About 10 minutes
    east down the main drag there’s a fancier market and deli
    called <a href="http://www.circlewmarket.com/" target="_blank" rel="noopener noreferrer">Circle W</a>,
    designed for country-going city people like us. These are all great places to for a quick bite.
  </Blurb>

  <Blurb title="Phoenicia">
    About 20 minutes south, this small town is home to both the
    famous <a href="https://www.phoeniciadiner.com/" target="_blank" rel="noopener noreferrer">Phoenicia Diner</a> and the equally
    famous <a href="http://sweetsue1984.wixsite.com/sweetsuesrestaurant" target="_blank" rel="noopener noreferrer">Sweet Sue’s</a>, known for
    its fantastic breakfast pancakes.
  </Blurb>

  <Blurb title="Slightly Farther">
    Woodstock and Kingston are each about 40 minutes away, and each has its own selection of cute,
    excellent, appropriately city-meets-country places. But the big-deal, New American,
    farm-to-table, <em>trés Brooklyn</em> destination restaurant around here
    is <a href="https://peekamooserestaurant.com/" target="_blank" rel="noopener noreferrer">Peekamoose</a>, about 40 minutes away in a town
    that is somehow still called Big Indian. No doubt some of you are already planning an excursion.
  </Blurb>

  <h2>Activities</h2>

  <Blurb title="Kaaterskill Falls" titleUrl="https://www.greatnortherncatskills.com/outdoors/kaaterskill-falls">
    The waterfall that inspired both the area’s name and the Hudson River School of painters,
    Kaaterskill Falls is a short drive away and a lovely place to go for a little hike. But
    be careful: According to
    the <a href="https://www.nytimes.com/2018/08/14/nyregion/kaaterskill-falls-catskills-deaths-instagram.html" target="_blank" rel="noopener noreferrer">Times</a>,
    this is dangerous place to take selfies (though we’ve
    done so <a href="https://www.instagram.com/p/BebRJ3kDmg8/" target="_blank" rel="noopener noreferrer">successfully</a>).
  </Blurb>

  <Blurb title="Olana" titleUrl="https://www.olana.org/">
    The home and studio of Hudson River School painter Frederic Church is 40 minutes away, right
    across the Hudson in Greenport. It’s an impressive house, a National Historic Landmark, and a
    State Historic Site that claims to be one of the most visited sites in New York.
  </Blurb>

  <Blurb title="Opus 40" titleUrl="https://www.opus40.org/">
    This remarkable sculpture park is its own work of art. Harvey Fite, a Bard professor, bought a
    disused quarry, used its stone in his pieces, and then transformed the space itself into a Land
    Art work that complements the pieces. It’s pretty cool.
  </Blurb>

  <Blurb title="Saugerties" titleUrl="https://www.getawaymavens.com/saugerties-ny/">
    The nearest big town, Saugerties has some cute shops and restaurants and, for some reason, a
    Woodstock museum. (Max Yasgur’s farm was in Bethel, 90 minutes away.) Be sure to check out
    the lighthouse, on the Hudson riverside.
  </Blurb>

  <Blurb title="Storm King Art Center" titleUrl="https://stormking.org">
    This sprawling, open-air museum is more than an hour from Deer Mountain, so it's perhaps an 
    ambitious day trip. But it's right off the New York Thruway, so it could make an excellent 
    detour on your way up or back from Tannersville. Heading north to Deer Mountain, take Exit 
    16. Heading south, take Exit 17. Either way, pack a lunch, follow the signs, and be sure to 
    take the obligatory photos under 
    Menashe Kadishman’s <a href="https://stormking.org/artist/menashe-kadishman/" target="_blank" rel="noopener noreferrer">"Suspended."</a>
  </Blurb>

  <Blurb title="Woodstock" titleUrl="http://escapebrooklyn.com/woodstock-ny/">
    Forty minutes from Deer Mountain, Woodstock is, well, Woodstock. It’s worth checking out
    and has plenty of places to eat, but expect plenty of tie-die and crystal among the vintage
    shops, galleries, and boutiques. We liked the Station Bar, a dive in an old train station,
    filled with vintage tchotchkes.
  </Blurb>

  <Blurb title="Zipline New York" titleUrl="http://ziplinenewyork.com/">
    Apparently the longest, fastest, and highest zipline in North America — and the second largest
    in the world — is at Hunter Mountain. Please don’t injure yourself while selfie-ing here, either.
  </Blurb>

  <Blurb>
    And <a href="http://dev.deermountaininn.com.s190402.gridserver.com/wp-content/uploads/2017/04/DMI_ExplorationGuide_FINAL.pdf" target="_blank" rel="noopener noreferrer">here</a> is
    the Deer Mountain Inn Exploration Guide of area activities, including other, more ambitious
    hiking options.
  </Blurb>

  </Layout>
)

export default ThingsToDo
